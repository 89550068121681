.survey-template-card-shadow {
  box-shadow: var(--card-shadow);
  min-height: 100px;
}

.survey-template-card-shadow .ant-card-head-title {
  font-weight: 600;
  display: flex;
}

.survey-template-card-shadow .ant-card-body {
  height: 100%;
}

.survey-template-card-shadow .ant-skeleton {
  height: 100%;
}

.survey-template-card {
  display: grid;
  gap: 5px;
  width: 180px;
  grid-template-rows: auto 1fr auto;
}
