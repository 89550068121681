.device-card-shadow {
  box-shadow: var(--card-shadow);
  width: 180px;
}

.device-card-shadow .ant-card-head-title {
  font-weight: 600;
}

.device-card-shadow .ant-card-body {
  height: 100%;
}

.device-card-shadow .ant-skeleton {
  height: 100%;
}

.device-card {
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: auto 1fr auto;
  gap: 5px;
}

.device-selected {
  border: 1px solid var(--device-selected-border);
}

.device-available {
  cursor: pointer;
}

.title-grid {
  display: grid;
  align-self: start;
}

.fontsize-10 {
  font-size: 10px;
}

.device-status-color {
  font-size: 15px;
  color: white !important;
}
