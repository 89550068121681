.measurements-chart-container {
  display: grid;
  height: 100%;
}

.measurements-chart-container .ant-spin-container {
  height: 100% !important;
}

#measurements-chart {
  height: 100%;
}
