.study-card-shadow {
  box-shadow: var(--card-shadow);
}

.study-card-shadow .ant-card-head-title {
  font-weight: 600;
}

.study-card-shadow .ant-card-body {
  height: 100%;
}

.study-card-shadow .ant-skeleton {
  height: 100%;
}

.study-content {
  display: grid;
  grid-template-rows: auto 1fr auto;
  margin-top: -10px;
  gap: 10px;
}

.study-content-stats {
  grid-row: 2;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 5px;
  margin: 10px 0px;
  justify-items: center;
}
