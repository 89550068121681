.login-content {
  display: grid;
  height: 100%;
  grid-template-columns: 1fr 260px;
}

.login-background {
  background-image: url("/public/img/background.jpg");
  background-size: cover;
  opacity: 0.65;
}

.login-sidebar {
  z-index: 2;
  background: var(--secondary-bkg-color);
  grid-column: 2;
  box-shadow: -15px 0px 20px -7px rgba(0, 0, 0, 0.28);
  padding: 10px;
  display: grid;
  grid-template-rows: 20px auto 2fr 1fr auto 60px;
  gap: 5px;
  grid-template-areas:
    ". .                  ."
    ". login-header       ."
    ". login-section      ."
    ". .                  ."
    ". login-demo-section ."
    ". .                  .";
}

.login-header-grid {
  text-transform: uppercase;
  grid-area: login-header;
  text-align: center;
  display: grid;
  grid-template-rows: 35px 35px;
}

.login-header {
  color: var(--header-color);
  font-weight: 700;
  font-size: 20px;
}

.login-sub-header {
  color: var(--header-color);
  font-weight: 600;
  font-size: 18px;
}

.login-section {
  display: grid;
  grid-area: login-section;
  grid-template-rows: 1fr 30px 20px 50px 1fr;
  text-align: center;
  justify-items: center;
  align-items: center;
}

.welcome-header {
  color: white;
  justify-content: center;
  font-weight: 700;
  font-size: 24px;
  grid-row: 2;
}

.login-demo-section {
  display: grid;
  grid-area: login-demo-section;
  justify-content: center;
  align-content: center;
}