.user-card-shadow {
  box-shadow: var(--card-shadow);
}

.user-card-shadow .ant-card-head-title {
  font-weight: 600;
}

.user-card-shadow .ant-card-body {
  height: 100%;
}

.user-card-shadow .ant-skeleton {
  height: 100%;
}

.user-card {
  display: grid;
  height: 100%;
  gap: 2px;
  justify-items: start;
}

.user-card-photo {
  border: 3px solid var(--btn-color);
}

.user-card-photo:hover {
  box-shadow: var(--card-shadow);
}
