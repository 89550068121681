.info-container {
  box-shadow: var(--card-shadow);
  border-radius: 5px;
  padding: 20px;
  width: 100%;
  font-size: 15px;
  display: table;
  table-layout: fixed;
}

.ant-row.info-container {
  display: table;
}

.info-cell-content .ant-statistic-content {
  font-size: 18px;
}

.stop-button.ant-btn {
  padding: 4px 10px;
}

.info-cell {
  padding-left: 10px;
  height: 100%;
  display: table-cell;
  width: auto;
  vertical-align: top;
}

.info-cell .ant-progress-text {
  width: auto;
}

.info-cell-title {
  color: #3fa9ff;
}

.info-cell-content {
  color: #828282;
}

.info-cell-icon {
  font-size: medium;
  margin: 3px;
}

.border-right {
  border-right: 1px solid #dbdbdb;
}

.analyze-spinner {
  text-align: center;
}

.action-cell {
  margin: auto;
  text-align: center;
  vertical-align: middle;
}

.item-menu {
  width: 5%;
}

.info-cell-center {
  justify-content: center;
}

@media screen and (min-width: 1600px) {
  .info-container,
  .info-container .ant-btn {
    font-size: 18px;
  }
  
  .info-cell-content  .ant-statistic-content {
    font-size: 21px;
  }

  .stop-button.ant-btn {
    height: 40px;
    padding: 6.4px 15px;
    font-size: 16px;
    border-radius: 4px;
  }

  .info-cell-icon {
    font-size: large;
    margin: 5px;
  }
  .info-cell {
    padding-left: 15px;
  }
}
