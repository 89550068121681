.chart-user-container {
  display: grid;
  min-height: 400px;
}
.chart-user-container .ant-spin-container {
  height: 100% !important;
}

#community-user-chart {
  height: 100%;
}
