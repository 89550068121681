.details-container {
  display: grid;
  width: 100%;
  min-height: 80vh;
  background: #fdfdfd;
  padding: 20px;
  grid-template-rows: auto 1fr auto;
}

.measurement-content {
  display: grid;
  width: 100%;
  height: 100%;
}

.ant-row {
  margin-bottom: 10px;
}
