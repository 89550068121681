.profile-menu {
  grid-column: 3;
  display: inline-flex;
  min-width: max-content;
}

.profile-menu.ant-menu {
  background: var(--main-bg-color) !important;
}

.profile-menu-item {
  padding: 0px 8px;
  cursor: pointer;
}
.profile-menu-item:hover {
  background: var(--secondary-bkg-color) !important;
}

.ant-menu-item {
  border-bottom-width: 0px !important;
}

.user-role-text {
  font-weight: bold;
}

.badge-menu {
  margin-right: 1.5em;
  cursor: pointer;
}
