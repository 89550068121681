.chart-community-container {
  display: grid;
  min-height: 400px;
}
.chart-community-container .ant-spin-container {
  height: 100% !important;
}

#community-chart {
  height: 100%;
}
