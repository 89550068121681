.graph {
  height: 50vh;
  width: 100%;
  padding: 10px;
  box-shadow: var(--card-shadow);
  border-radius: 5px;
}

.graph-spinner {
  width: 100%;
  height: 100%;
}

.full-width{
  width: 100%;
}