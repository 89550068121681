.sidebar {
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.75);
}

.sidebar .ant-menu-inline-collapsed {
  width: 64px;
}

.sidebar .ant-menu-inline-collapsed > .ant-menu-item {
  padding: 0 24px;
}

.sidebar .ant-menu-inline-collapsed .ant-menu-submenu > .ant-menu-submenu-title {
  padding: 0 24px;
}

.logo-grid {
  cursor: pointer;
  transition: color 0.3s ease;
  display: grid;
}

.logo {
  height: 32px;
  margin: 10px;
  align-self: center;
  justify-self: center;
}

.logo-grid:hover {
  background: rgba(255, 255, 255, 0.2);
}
