.profile-photo {
  display: grid;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-items: center;
}

.upload-control {
  border: 3px solid var(--btn-color);
}

.upload-control:hover {
  cursor: pointer;
  box-shadow: var(--card-shadow);
}
