.finish-step-container {
  text-align: center;
  height: 65vh;
  position: relative;
}

.finish-step-contents {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
