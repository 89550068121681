.calibration-page {
  display: grid;
  width: 100%;
  height: 100%;
  background: #fdfdfd;
  padding: 20px;
  gap: 20px;
  grid-template-rows: 5% 45% 45%;
  grid-template-columns: 70% 30%;
  overflow: auto;
}

.calibration-table .ant-table-tbody > tr > td {
  padding: 0px !important;
  text-align: center;
}

.calibration-table .ant-table-thead > tr > th {
  text-align: center;
  background-color: var(--main-bg-color);
}

.calibration-chart {
  display: grid;
  width: 100%;
  height: 100%;
}

.calibration-section {
  width: 100%;
  height: 100%;
}

.calibration-section h1 {
  text-align: left;
}

#chartdiv {
  position: relative;
  z-index: 99;
  height: 70vh;
  grid-column-start: 1;
}

.chart-menu {
  grid-column-start: 2;
}

.calibration-table {
  grid-column: 2;
  grid-row-start: 2;
  grid-row-end: 3;
  padding-right: 5px;
}

.check-inner-sensors-button {
  background-color: green;
}

.check-outer-sensors-button {
  background-color: red;
}
