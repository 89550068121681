.footer-grid {
  display: grid;
  text-align: center;
  color: white !important;
  grid-template-columns: 1fr auto 1fr;
  /* box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.75); */
}

.copyright {
  grid-column: 2;
  justify-self: center;
}

.version {
  justify-self: end;
  margin-right: 20px;
}

.footerIcon {
  height: 1em;
  margin-right: 0.5em;
}
