h1 {
    color: #244C5A;
}

h1 .accent {
    color: #63B1BC;
}

.content {
    margin: auto;
    font-family: Segoe UI;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    margin-top: 2em;
}

.logo {
    max-height: 3em;
    margin-top: 0.5em;
    margin-left: 0.5em;
}

.content img {
    max-width: 100%;

}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .logo {
        max-height: 2em;
    }

    .content {
        font-size: small !important;
    }
}