.sidebar {
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.75);
}

.logo-grid {
  cursor: pointer;
  transition: color 0.3s;
  display: grid;
}

.logo {
  height: 32px;
  margin: 10px;
  align-self: center;
  justify-self: center;
}

.logo-grid:hover {
  background: rgba(255, 255, 255, 0.2);
}

.trigger-content {
  grid-column: 1;
  font-size: 18px;
  align-self: center;
  justify-self: center;
}

.trigger-content:hover {
  color: rgba(255, 255, 255, 0.6);
}

.main-layout {
  height: 100%;
  display: grid;
  grid-template-columns: auto 1fr;
}

.site-layout {
  display: grid;
  height: 100%;
  width: 100% !important;
  grid-template-rows: auto 1fr auto;
}

.header-layout-grid {
  background: var(--main-bg-color);
  color: #fff !important;
  padding: 0 !important;
  display: grid;
  grid-template-columns: 50px 1fr auto;
}

.site-layout-content {
  padding: 10px 30px 25px 30px;
  overflow: auto;
}
.responsive-card-shadow {
  height: 100%;
  width: 100%;
  min-width: 300px;
  box-shadow: var(--card-shadow);
  display: grid;
  grid-template-rows: auto 1fr;
}

.responsive-card-shadow .ant-card-head-title {
  font-weight: 600;
}

.responsive-card-shadow .ant-card-body {
  grid-row: 2;
  height: 100%;
}

.responsive-card-shadow .ant-skeleton {
  height: 100%;
}

.dev-tag.ant-tag-checkable {
  width: 48px;
  margin: 0px !important;
  text-align: center;
  background: white;
  color: black;
}
.dev-tag.ant-tag-checkable-checked {
  background: var(--dev-color) !important;
  border-color: var(--dev-color) !important;
  color: white;
}

.qa-tag.ant-tag-checkable {
  width: 48px;
  margin: 0px !important;
  text-align: center;
  background: white;
  color: black;
}
.qa-tag.ant-tag-checkable-checked {
  background: var(--qa-color) !important;
  border-color: var(--qa-color) !important;
  color: white;
}

.prod-tag.ant-tag-checkable {
  width: 48px;
  margin: 0px !important;
  text-align: center;
  color: white;
  color: black;
}
.prod-tag.ant-tag-checkable-checked {
  background: var(--prod-color) !important;
  border-color: var(--prod-color) !important;
  color: white;
}
