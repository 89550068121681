.signal-container {
  color: white;
  width: 100%;
  height: 100%;
  font-size: 18px;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-row-gap: 20px;
}

.signal-statistic {
  display: grid;
  grid-template-columns: auto auto 1fr 130px;
  align-items: center;
  grid-gap: 20px;
}
