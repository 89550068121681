:root {
  --main-color-yellow: #ffcc33;
  --main-color-yellow-light: #ffd557;
  --btn-color: #ffcc33;
  --main-bg-color: #4e8c98;
  --secondary-bkg-color: #72b5bc;
  --header-color: #33485b;
  --admin-color: #ffcc33;
  --researcher-color: #52c41a;
  --clinician-color: #57aaff;
  --unknown-color: #4e8c98;
  --dev-color: #52c41a;
  --qa-color: #13c2c2;
  --prod-color: #1890ff;
  --card-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16),
    0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
  --measurement-color: #ffcc33;
  --calibration-selected-color: #adc9e4;

  --loading-bar-begin-color: #A2D8D3;
  --loading-bar-end-color: #437983;
  --study-draft-color: gray;
  --study-preview-color: #ffcc33;
  --study-active-color: #52c41a;
  --study-completed-color: #1890ff;

  --rating-min-color: #f50;
  --rating-max-color: #52c41a;
  --delete-icon-color: #ff4d4f;

  --member-color: #1890ff;
  --disabled-member-color: #ff4d4f;
  --investigator-color: #a0d911;

  --research-area-color: #722ed1;
  --org-color: #13c2c2;
  --study-type-color: #faad14;

  --device-status-error: #fa8c16;
  --device-status-buffer: #fa8c16;
  --device-status-ready: #52c41a;
  --device-status-sending: #2db7f5;
  --device-status-advised: #2db7f5;
  --device-status-offline: #ff4d4f;
  --device-selected-border: #1890ff;

  --nose-calibration-required: #e90a0a;
  --nose-calibration-advised: #fa8c16;
  --nose-calibration-ready: #52c41a;

  --measurement-status-unknown: #fa8c16;
  --measurement-status-completed: #52c41a;
  --measurement-status-inprogress: #2db7f5;
  --measurement-status-error: #ff4d4f;

  --survey-status-empty: gray;
  --survey-status-inprogress: #2db7f5;
  --survey-status-completed: #52c41a;
  --survey-status-submitted: #722ed1;

  --measurement-regular-color: #52c41a;
  --measurement-ambient-color: #722ed1;
  --measurement-test-color: #fa8c16;
  --measurement-calibration-color: #108ee9;

  --default-tooltip-color: #108ee9;
  --question-icon-color: #1890ff;

  --ambient-status-100: #52c41a;
  --ambient-status-80: #2db7f5;
  --ambient-status-60: #ffcc33;
  --ambient-status-40: #fa8c16;
  --ambient-status-20: #ff4d4f;

  --analysis-result-success: #52c41a;
  --analysis-result-uncertain: #fa8c16;
  --analysis-result-failed: #ff4d4f;

  --csv-icon-color: #52c41a;
  --xls-icon-color: green;

  --white-background-color: white;
}

.App {
  height: 100%;
}

.white {
  background: white;
}

.shadow {
  box-shadow: var(--card-shadow);
}

.ant-typography-copy {
  color: #1890ff !important;
}

.main-content {
  display: grid;
  width: 100%;
  height: 100%;
  background: #fdfdfd;
  padding: 20px;
  grid-gap: 20px;
  gap: 20px;
}
