.dashboard-content {
  display: grid;
  gap: 10px;
  height: 100%;
  grid-template-rows: auto 1fr;
}

.dash-card-shadow {
  min-height: 100%;
  max-width: 100%;
  min-width: 280px;
  box-shadow: var(--card-shadow);
  display: grid;
  grid-template-rows: auto 1fr;
}

.dash-card-shadow .ant-card-head-title {
  font-weight: 600;
}

.dash-card-shadow .ant-card-body {
  height: 100%;
  overflow: auto;
}

.dash-card-shadow .ant-skeleton {
  height: 100%;
}

.ant-row {
  margin-bottom: 10px;
}