.container-analyze {
  color: white;
  width: 100%;
  height: 100%;
  padding: 10px;
  font-size: 18px;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-row-gap: 20px;
}

.device-details {
  grid-column-start: 2;
  grid-row-start: 1;
  padding-bottom: 10px;
}

.measurement-analyze-result {
  display: grid;
  width: 100%;
  grid-template-columns: auto auto;
  grid-row-start: 2;
  grid-column-start: 2;
}

.measurement-details-title-grid {
  display: grid;
  align-self: start;
  grid-template-columns: auto;
}

.measurement-details-space {
  grid-column-start: 2;
}

.ant-row {
  margin-bottom: 10px;
}
