.device-chart-container {
  display: grid;
  height: 100%;
}

.device-chart-container .ant-spin-container {
  height: 100% !important;
}

#devices-chart {
  height: 100%;
}
