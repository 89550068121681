.stepper-grid {
  display: grid;
  width: 100%;
  background: #fdfdfd;
  padding: 20px;
  gap: 20px;
  grid-template-rows: auto auto 1fr;
}

.change-study-button {
  margin-right: 15px;
}

.clinician-toggle-button {
  margin-left: 10px;
}
