.container-survey {
  padding-top: 15px;
}

.survey-study-title {
  padding-bottom: 15px;
  display: grid;
  width: 100%;
  grid-template-columns: 2.7% auto;
}

.survey-study-title .study-title {
  font-size: 30px;
  grid-column-start: 2;
}

.survey-action {
  display: grid;
  width: 100%;
  padding-top: 10px;
  grid-template-columns: auto 0.1fr 0.1fr 0.1fr;
}

.survey-action .preload-survey-button {
  background-color: #3399ff;
  margin-right: 5px;
  grid-column-start: 2;
}

.survey-action .submit-button {
  background-color: #ffcc33;
  grid-column-start: 4;
  margin-right: 5px;
}

.survey-action .save-step-button {
  grid-column-start: 3;
  margin-bottom: -32px;
  margin-left: -5px;
}
