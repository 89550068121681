.landing-logo {
  height: 38px;
  align-self: center;
}

.header-grid {
  z-index: 5;
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr auto;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
}
