.measurements-grid {
  display: grid;
  width: 100%;
  background: #fdfdfd;
  padding: 20px;
  gap: 20px;
  grid-template-rows: auto auto auto 1fr;
}

.download-grid {
  display: grid;
  grid-template-columns: auto 1fr auto;
}
