.survey-grid {
  display: grid;
  width: 100%;
  height: 100%;
  background: #fdfdfd;
  padding: 20px;
}

.survey-content {
  height: 100%;
  display: grid;
  overflow: auto;
}

.survey-content .ant-tabs-content-holder {
  height: 100% !important;
  overflow: auto !important;
}
.survey-action {
  display: grid;
  width: 100%;
  padding-top: 10px;
  grid-template-columns: auto 5% 5%;
}

.survey-action .submit-button {
  grid-column-start: 2;
  margin-right: 5px;
}

.survey-action .next-step-button {
  grid-column-start: 3;
}