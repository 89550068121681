.support-grid {
  display: grid;
  gap: 10px;
  padding: 0px 10px;
}

.breathbase-link {
  vertical-align: middle;
  justify-self: center;
  align-self: center;
  color: var(--secondary-bkg-color);
}