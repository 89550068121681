.container-measurement-edit-survey {
  display: grid;
  width: 100%;
  background: #fdfdfd;
  padding: 20px;
  gap: 20px;
  grid-template-columns: 2.7% auto;
}

.edit-survey-details {
  grid-column-start: 2;
}

.survey-study-title {
  padding-bottom: 15px;
  display: grid;
  width: 100%;
}

.measurement-id-title {
  grid-column-start: 2;
  padding-left: 25px;
  font-size: 30px;
}

.edit-survey-action {
  display: grid;
  width: 100%;
  padding-top: 10px;
  grid-template-columns: auto 0.1fr 0.1fr 0.1fr 0.1fr;
  grid-column-start: 2;
}

.preload-survey-button {
  background-color: #3399ff;
  margin-right: 7px;
  grid-column-start: 2;
}

.cancel-survey-button {
  margin-right: 7px;
  grid-column-start: 3;
}

.save-survey-button {
  margin-right: 7px;
  grid-column-start: 4;
}
.submit-survey-button {
  background-color: #ffcc33;
  grid-column-start: 5;
}
